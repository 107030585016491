<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          calls,
          Item,
          reload
        }"
    >
      <v-row>
        <v-col
            v-if="Item.content"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <div
                  v-html="Item.content"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <v-progress-circular
              v-if="loading"
              :indeterminate="true"
          />

          <template
              v-else
          >
            <template
                v-if="Application"
            >
              <template
                  v-if="Application.deletable"
              >
                <v-btn
                    type="submit"
                    :large="true"
                    :block="true"
                    color="primary"
                    @click.stop.prevent="$refs.deleteEvent.$el.click()"
                >
                  <v-icon>
                    mdi-cancel
                  </v-icon>

                  Zrušiť
                </v-btn>

                <DeleteEvent
                    ref="deleteEvent"
                    :eventId="Item.id"
                    class="d-none"
                    @onDeleteEvent="() => {
                      reload()
                    }"
                />
              </template>

              <template
                  v-else
              >
                Stav:

                <v-badge
                    :inline="true"
                    :color="Application.statusColorEnum"
                    :content="Application.statusEnum"
                />
              </template>
            </template>

            <template
                v-else
            >
              <v-btn
                  type="submit"
                  :large="true"
                  :block="true"
                  color="primary"
                  @click.stop.prevent="$refs.createEvent.$el.click()"
              >
                <v-icon>
                  mdi-pen
                </v-icon>

                Zaregistrovať sa
              </v-btn>

              <CreateEvent
                  ref="createEvent"
                  :eventId="Item.id"
                  class="d-none"
                  @onCreateEvent="() => {
                  reload()
                }"
              />
            </template>
          </template>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import EventsAuth from '@/services/events-auth'
  import ApplicationsAuth from '@/services/applications-auth'

  import CreateEvent from '@/components/ApplicationAuth/CreateEvent.vue'
  import DeleteEvent from '@/components/ApplicationAuth/DeleteEvent.vue'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'EventAuth',
    components: {
      CreateEvent,
      DeleteEvent,
      NotBlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-application',
        Item: 'Event',
        failLink: '/events-auth',
        loading: true,
        Application: null
      }
    },
    methods: {
      fetchData() {

        return EventsAuth
          .read({
            id: this.$route.params.id
          })
          .then((response) => {
            this.loading = true

            ApplicationsAuth
              .readEvent({
                eventId: response.data.data.Event.id
              })
              .then((response) => {
                this.Application = response.data.data.Application
              })
              .catch(() => {
                this.Application = null
              })
              .finally(() => {
                this.loading = false
              })

            return response
          })
      },
      setTitle(Item) {
        return Item.title + ' | ' + this.$moment(Item.dateFromPlain).format('DD.MM.YYYY') + (Item.multipleDays ? ' - ' + this.$moment(Item.dateToPlain).format('DD.MM.YYYY') : '' ) + ' | ' + Item.startPlain + ' - ' + Item.endPlain
      },
      setSubtitle() {
        return 'Udalosť'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Udalosti',
            to: '/events-auth',
            exact: true
          }
        ]
      }
    }
  }
</script>
