<template>
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          :maxWidth="maxWidth"
          :icon="icon"
          :title="title"
          :hasFilter="hasFilter"
          :colspan="colspan"
          :empty="empty"
          :defaultFilters="defaultFilters"
          :defaultOrder="defaultOrder"
          :defaultBy="defaultBy"
          :defaultLimit="defaultLimit"
          :fetchData="fetchData"
          :itemsKey="itemsKey"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <Create
              @onCreate="init"
          />

          <v-btn
              :icon="true"
              :outlined="true"
              color="default"
              :href="$global.axiosUrl() + 'app/volunteers/export'"
              target="_blank"
          >
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-col
              cols="12"
              sm="6"
              md="12"
              xl="3"
          >
            <v-text-field
                v-model="params.query"
                :error-messages="errors.query"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Vyhľadávanie"
                :clearable="true"
                @change="setSearch"
            />
          </v-col>
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.placePrimary"
                :error-messages="errors.Filters?.placePrimary"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Primárne miesto"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="volunteer_place"
            />
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.placeSecondary"
                :error-messages="errors.Filters?.placeSecondary"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Sekundárne miesto"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="volunteer_place"
            />
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.positionPrimary"
                :error-messages="errors.Filters?.positionPrimary"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Alternatíva č.1"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="volunteer_position"
            />
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.positionSecondary"
                :error-messages="errors.Filters?.positionSecondary"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Alternatíva č.2"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="volunteer_position"
            />
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.positionTertiary"
                :error-messages="errors.Filters?.positionTertiary"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Alternatíva č.3"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="volunteer_position"
            />
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.status"
                :error-messages="errors.Filters?.status"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Stav"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="volunteer_status"
            />
          </v-col>
        </template>

        <template
            v-slot:list-head="{
              items,
              order,
              by,
              init,
              setOrderBy
            }"
        >
          <th>
            <v-checkbox
                v-model="all"
                :dense="true"
                hide-details="auto"
                @change="(value) => {
                  ids = []

                  if (value) {
                    items.forEach((_Item) => {
                      ids.push(_Item.id)
                    })
                  }
                }"
            />
          </th>

          <th
              :colspan="2"
              class="text-left fixed"
          >
            <OrderByList
                label="Meno & Priezvisko"
                value="usedat.name"
                :order="order"
                :by="by"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            E-mail
          </th>

          <th>
            Telefón
          </th>

          <th>
            Dátum narodenia
          </th>

          <th>
            Primárne miesto
          </th>

          <th>
            Sekundárne miesto
          </th>

          <th>
            Alternatíva č.1
          </th>

          <th>
            Alternatíva č.2
          </th>

          <th>
            Alternatíva č.3
          </th>

          <th>
            Stav
          </th>

          <th
              class="text-right fixed"
          >
            <UpdateMass
                v-if="ids.length > 0"
                :ids="ids"
                @onUpdateMass="init"
            />
          </th>
        </template>

        <template
            v-slot:list-body-standard="{
              items,
              Item,
              init
            }"
        >
          <td>
            <v-checkbox
                v-model="ids"
                :value="Item.id"
                :dense="true"
                hide-details="auto"
                @change="() => {
                  all = ids.length === items.length
                }"
            />
          </td>

          <td
              width="80px"
          >
            <a
                :href="$global.cdnUrl() + 'media/files/by-token/' + Item.photoToken"
                target="_blank"
            >
              <v-img
                  :src="$global.cdnUrl() + 'media/files/by-token/' + Item.photoToken + '/40/40'"
                  alt="Fotografia"
                  max-width="40px"
                  max-height="40px"
              />
            </a>
          </td>

          <td
              class="text-left fixed"
          >
            <TruncateHelper
                :title="Item.name"
                maxWidth="300px"
            >
              {{ Item.name }}
            </TruncateHelper>
          </td>

          <td>
            <a
                :href="'mailto: ' + Item.email"
            >
              <TruncateHelper
                  :title="Item.email"
                  maxWidth="300px"
              >
                {{ Item.email }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            <a
                :href="'tel: ' + Item.phone"
            >
              <TruncateHelper
                  :title="Item.phone"
                  maxWidth="300px"
              >
                {{ Item.phone }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            {{ Item.birthDatePlain|moment('DD.MM.YYYY') }}
          </td>

          <td>
            {{ Item.placePrimaryEnum }}
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.placeSecondaryEnum"
            >
              {{ Item.placeSecondaryEnum }}
            </SpanIsEmpty>
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.positionPrimaryEnum"
            >
              {{ Item.positionPrimaryEnum }}
            </SpanIsEmpty>
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.positionSecondaryEnum"
            >
              {{ Item.positionSecondaryEnum }}
            </SpanIsEmpty>
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.positionTertiaryEnum"
            >
              {{ Item.positionTertiaryEnum }}
            </SpanIsEmpty>
          </td>

          <td>
            <v-badge
                :inline="true"
                :color="Item.statusColorEnum"
                :content="Item.statusEnum"
            />

            <v-tooltip
                v-if="Item.note"
                :bottom="true"
            >
              <template
                  v-slot:activator="{
                    on,
                    attrs
                  }"
              >
                <span
                    v-on="on"
                    v-bind="attrs"
                >
                  <v-icon>
                    mdi-information
                  </v-icon>
                </span>
              </template>

              {{ Item.note }}
            </v-tooltip>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/volunteers/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>

            <Update
                :id="Item.id"
                @onUpdate="init"
            />

            <Delete
                v-if="Item.deletable"
                :id="Item.id"
                @onDelete="init"
            />
          </td>
        </template>

        <template
            v-slot:pagination-count="{
              limit,
              setLimit
            }"
        >
          <LimitPagination
              v-for="(_limit, i) in [10, 25, 50, 100]"
              :key="i"
              :label="_limit"
              :value="_limit"
              :limit="limit"
              @setLimit="setLimit"
          />
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Volunteers from '@/services/volunteers'

  import Create from '@/components/Volunteers/Create'
  import Update from '@/components/Volunteers/Update'
  import UpdateMass from '@/components/Volunteers/UpdateMass'
  import Delete from '@/components/Volunteers/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  import SpanIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Span'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Volunteers',
    components: {
      Create,
      Update,
      UpdateMass,
      Delete,
      BlankPage,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper,
      SpanIsEmpty,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 'calc(100% - 124px)',
        icon: 'mdi-handshake',
        hasFilter: true,
        colspan: 13,
        empty: 'Nenašli sa žiadni dobrovoľníci.',
        itemsKey: 'volunteers',
        defaultFilters: {
          placePrimary: null,
          placeSecondary: null,
          positionPrimary: null,
          positionSecondary: null,
          positionTertiary: null,
          status: null
        },
        defaultOrder: 'usedat.name',
        defaultBy: 'ASC',
        defaultLimit: 25,
        all: false,
        ids: []
      }
    },
    methods: {
      fetchData(params) {
        return Volunteers
          .search({
            params: params
          })
      },
      setTitle() {
        return 'Dobrovoľníci'
      }
    }
  }
</script>
