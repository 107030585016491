<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :saveData="profileSetVolunteerData"
      @onSave="onProfileSetVolunteer"
  >
    <template
        v-slot:default="{
          Item,
          close,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Osobné údaje"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.username"
                    :error-messages="errors.username"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Prezývka"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.gender"
                    :error-messages="errors.gender"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Pohlavie"
                    indexGroupValue="volunteer_gender"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-row
                    align="center"
                >
                  <v-col
                      cols="12"
                      lg="6"
                  >
                    <v-input
                        :error-messages="errors.Photo"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        :label="
                          !photo
                            ? 'Fotografia'
                            : null
                        "
                        :persistent-hint="true"
                        hint="Odporúčaný formát je 1:1"
                    >
                      <template
                          v-slot:default
                      >
                        &nbsp;

                        <v-row
                            align="center"
                        >
                          <v-col
                              v-if="photo"
                              cols="auto"
                          >
                            <a
                                :href="$global.cdnUrl() + 'media/files/by-token/' + photo"
                                target="_blank"
                            >
                              <v-img
                                  :src="$global.cdnUrl() + 'media/files/by-token/' + photo + '/120/120'"
                                  alt="Fotografia"
                                  max-width="120px"
                                  max-height="120px"
                              />
                            </a>
                          </v-col>

                          <v-col
                              cols="auto"
                          >
                            <v-row>
                              <v-col
                                  cols="12"
                              >
                                <File
                                    @onFile="(response) => {
                                      ItemForm.Photo = response.data.data.id

                                      photo = response.data.data.token
                                    }"
                                />
                              </v-col>

                              <v-col
                                  v-if="ItemForm.Photo && photo"
                                  cols="12"
                              >
                                <v-btn
                                    :icon="true"
                                    :outlined="true"
                                    color="error"
                                    @click.stop.prevent="() => {
                                      ItemForm.Photo = null

                                      photo = null
                                    }"
                                >
                                  <v-icon>
                                    mdi-auto-fix
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </template>
                    </v-input>
                  </v-col>

                  <v-col
                      cols="12"
                      lg="6"
                  >
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="ItemForm.firstName"
                            :error-messages="errors.firstName"
                            :filled="true"
                            :dense="true"
                            hide-details="auto"
                            label="Meno"
                        />
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="ItemForm.lastName"
                            :error-messages="errors.lastName"
                            :filled="true"
                            :dense="true"
                            hide-details="auto"
                            label="Priezvisko"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.email"
                    :error-messages="errors.email"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="E-mail"
                    type="email"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.phone"
                    :error-messages="errors.phone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                    :persistent-hint="true"
                    hint="Uvádzajte v tvare 090XXXXXXX"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.birthDate"
                    :error-messages="errors.birthDate"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Dátum narodenia"
                    type="date"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.idCard"
                    :error-messages="errors.idCard"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Číslo OP"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <ComboboxRegisterIndexesIndexGroupValue
                    v-model="ItemForm.shirtSize"
                    :error-messages="errors.shirtSize"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Veľkosť hornej časti oblečenia"
                    indexGroupValue="volunteer_shirt_size"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <ComboboxRegisterIndexesIndexGroupValue
                    v-model="ItemForm.shortSize"
                    :error-messages="errors.shortSize"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Veľkosť spodnej časti oblečenia"
                    indexGroupValue="volunteer_short_size"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="ItemForm.iban"
                    :error-messages="errors.iban"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="IBAN"
                />
              </v-col>
            </v-row>
          </template>
        </CardPart>
      </v-col>

      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Trvalý pobyt"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="5"
              >
                <v-text-field
                    v-model="ItemForm.residenceStreet"
                    :error-messages="errors.residenceStreet"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Ulica"
                    :persistent-hint="true"
                    hint="Uvádzajte celú adresu trváleho bydliska"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="ItemForm.residenceCity"
                    :error-messages="errors.residenceCity"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Mesto"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="3"
              >
                <v-text-field
                    v-model="ItemForm.residenceZip"
                    :error-messages="errors.residenceZip"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="PSČ"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <ComboboxRegisterIndexesIndexGroupValue
                    v-model="ItemForm.residenceCountry"
                    :error-messages="errors.residenceCountry"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Krajina"
                    indexGroupValue="volunteer_country"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-checkbox
                    v-model="ItemForm.correspondenceAnotherAsResidence"
                    :error-messages="errors.correspondenceAnotherAsResidence"
                    :dense="true"
                    hide-details="auto"
                    label="Korešpondenčná adresa je iná ako trvalý pobyt"
                />
              </v-col>

              <template
                  v-if="ItemForm.correspondenceAnotherAsResidence"
              >
                <v-col
                    cols="12"
                    sm="5"
                >
                  <v-text-field
                      v-model="ItemForm.correspondenceStreet"
                      :error-messages="errors.correspondenceStreet"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Ulica"
                      :persistent-hint="true"
                      hint="Uvádzajte celú korešpondečnú adresu"
                  />
                </v-col>

                <v-col
                    cols="12"
                    sm="4"
                >
                  <v-text-field
                      v-model="ItemForm.correspondenceCity"
                      :error-messages="errors.correspondenceCity"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Mesto"
                  />
                </v-col>

                <v-col
                    cols="12"
                    sm="3"
                >
                  <v-text-field
                      v-model="ItemForm.correspondenceZip"
                      :error-messages="errors.correspondenceZip"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="PSČ"
                  />
                </v-col>

                <v-col
                    cols="12"
                >
                  <ComboboxRegisterIndexesIndexGroupValue
                      v-model="ItemForm.correspondenceCountry"
                      :error-messages="errors.correspondenceCountry"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Krajina"
                      indexGroupValue="volunteer_country"
                  />
                </v-col>
              </template>
            </v-row>
          </template>
        </CardPart>
      </v-col>

      <v-col
          v-if="$calculate.eventMinor(ItemForm.birthDate)"
          cols="12"
      >
        <CardPart
            subtitle="Zákonný zástupca"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.parentFirstName"
                    :error-messages="errors.parentFirstName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Meno"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.parentLastName"
                    :error-messages="errors.parentLastName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Priezvisko"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.parentEmail"
                    :error-messages="errors.parentEmail"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="E-mail"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.parentPhone"
                    :error-messages="errors.parentPhone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                    :persistent-hint="true"
                    hint="Uvádzajte v tvare 090XXXXXXX"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.parentBirthDate"
                    :error-messages="errors.parentBirthDate"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Dátum narodenia"
                    type="date"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.parentIdCard"
                    :error-messages="errors.parentIdCard"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Číslo OP"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-checkbox
                    v-model="ItemForm.parentResidenceAnotherAsResidence"
                    :error-messages="errors.parentResidenceAnotherAsResidence"
                    :dense="true"
                    hide-details="auto"
                    label="Trvalý pobyt zákonného zástupcu je iný ako trvalý pobyt dieťaťa"
                />
              </v-col>

              <template
                  v-if="ItemForm.parentResidenceAnotherAsResidence"
              >
                <v-col
                    cols="12"
                    sm="5"
                >
                  <v-text-field
                      v-model="ItemForm.parentResidenceStreet"
                      :error-messages="errors.parentResidenceStreet"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Ulica"
                      :persistent-hint="true"
                      hint="Uvádzajte celú adresu trváleho bydliska"
                  />
                </v-col>

                <v-col
                    cols="12"
                    sm="4"
                >
                  <v-text-field
                      v-model="ItemForm.parentResidenceCity"
                      :error-messages="errors.parentResidenceCity"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Mesto"
                  />
                </v-col>

                <v-col
                    cols="12"
                    sm="3"
                >
                  <v-text-field
                      v-model="ItemForm.parentResidenceZip"
                      :error-messages="errors.parentResidenceZip"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="PSČ"
                  />
                </v-col>

                <v-col
                    cols="12"
                >
                  <ComboboxRegisterIndexesIndexGroupValue
                      v-model="ItemForm.parentResidenceCountry"
                      :error-messages="errors.parentResidenceCountry"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Krajina"
                      indexGroupValue="volunteer_country"
                  />
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-checkbox
                      v-model="ItemForm.parentCorrespondenceAnotherAsResidence"
                      :error-messages="errors.parentCorrespondenceAnotherAsResidence"
                      :dense="true"
                      hide-details="auto"
                      label="Korešpondenčná adresa je iná ako trvalý pobyt"
                  />
                </v-col>

                <template
                    v-if="ItemForm.parentCorrespondenceAnotherAsResidence"
                >
                  <v-col
                      cols="12"
                      sm="5"
                  >
                    <v-text-field
                        v-model="ItemForm.parentCorrespondenceStreet"
                        :error-messages="errors.parentCorrespondenceStreet"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Ulica"
                        :persistent-hint="true"
                        hint="Uvádzajte celú korešpondečnú adresu"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      sm="4"
                  >
                    <v-text-field
                        v-model="ItemForm.parentCorrespondenceCity"
                        :error-messages="errors.parentCorrespondenceCity"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Mesto"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      sm="3"
                  >
                    <v-text-field
                        v-model="ItemForm.parentCorrespondenceZip"
                        :error-messages="errors.parentCorrespondenceZip"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="PSČ"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <ComboboxRegisterIndexesIndexGroupValue
                        v-model="ItemForm.parentCorrespondenceCountry"
                        :error-messages="errors.parentCorrespondenceCountry"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Krajina"
                        indexGroupValue="volunteer_country"
                    />
                  </v-col>
                </template>
              </template>
            </v-row>
          </template>
        </CardPart>
      </v-col>

      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Núdzový kontakt"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="ItemForm.emergencyFirstName"
                    :error-messages="errors.emergencyFirstName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Meno"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="ItemForm.emergencyLastName"
                    :error-messages="errors.emergencyLastName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Priezvisko"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="ItemForm.emergencyPhone"
                    :error-messages="errors.emergencyPhone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                    :persistent-hint="true"
                    hint="Uvádzajte v tvare 090XXXXXXX"
                />
              </v-col>
            </v-row>
          </template>
        </CardPart>
      </v-col>

      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Dotazník"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <ComboboxRegisterIndexesIndexGroupValue
                    v-model="ItemForm.education"
                    :error-messages="errors.education"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Vzdelanie"
                    indexGroupValue="volunteer_education"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="ItemForm.school"
                    :error-messages="errors.school"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Škola"
                    :persistent-hint="true"
                    hint="Uveďte názov školy najvyššieho dosiahnutého stupňa vzdelania"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="ItemForm.employment"
                    :error-messages="errors.employment"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Zamestnanie"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <ComboboxRegisterIndexesIndexGroupValue
                    :value="languageTypes"
                    :error-messages="errors.languageSkills"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Jazykové znalosti"
                    :multiple="true"
                    :small-chips="true"
                    indexGroupValue="volunteer_language_types"
                    @change="(values) => {
                      backupLanguageSkills = languageSkills

                      languageSkills = []

                      if (values.length > 0) {
                        values.forEach((_value) => {
                          backupLanguageSkill = backupLanguageSkills.find((_backupLanguageSkill) => _backupLanguageSkill.type === _value)

                          languageSkills.push({
                            type: _value,
                            level: backupLanguageSkill?.level
                          })
                        })
                      }
                    }"
                />
              </v-col>

              <v-col
                  v-if="languageSkills.length > 0"
                  :key="'languageSkills-' + languageSkills.length"
                  cols="12"
              >
                <v-row>
                  <v-col
                      v-for="(_languageSkill, i) in languageSkills"
                      :key="i"
                      cols="12"
                      sm="6"
                  >
                    <ComboboxRegisterIndexesIndexGroupValue
                        :value="languageLevels[i]"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        :label="'Úroveň: ' + _languageSkill.type"
                        indexGroupValue="volunteer_language_levels"
                        @change="(value) => {
                        _languageSkill.level = value
                      }"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
              >
                <ComboboxRegisterIndexesIndexGroupValue
                    :value="pcTypes"
                    :error-messages="errors.pcSkills"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="PC znalosti"
                    :multiple="true"
                    :small-chips="true"
                    indexGroupValue="volunteer_pc_types"
                    @change="(values) => {
                      backupPcSkills = pcSkills

                      pcSkills = []

                      if (values.length > 0) {
                        values.forEach((_value) => {
                          backupPcSkill = backupPcSkills.find((_backupPcSkill) => _backupPcSkill.type === _value)

                          pcSkills.push({
                            type: _value,
                            level: backupPcSkill?.level
                          })
                        })
                      }
                    }"
                />
              </v-col>

              <v-col
                  v-if="pcSkills.length > 0"
                  :key="'pcSkills-' + pcSkills.length"
                  cols="12"
              >
                <v-row>
                  <v-col
                      v-for="(_pcSkill, i) in pcSkills"
                      :key="i"
                      cols="12"
                      sm="6"
                  >
                    <ComboboxRegisterIndexesIndexGroupValue
                        :value="pcLevels[i]"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        :label="'Úroveň: ' + _pcSkill.type"
                        indexGroupValue="volunteer_pc_levels"
                        @change="(value) => {
                        _pcSkill.level = value
                      }"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.placePrimary"
                    :error-messages="errors.placePrimary"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Primárne miesto pôsobenia dobrovoľníckej činnosti"
                    :persistent-hint="true"
                    hint="Organizátor nie je povinný poskytnúť dobrovoľníkovi ubytovanie počas výkonu dobrovoľníckej činnosti."
                    indexGroupValue="volunteer_place"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.placeSecondary"
                    :error-messages="errors.placeSecondary"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Sekundárne miesto pôsobenia dobrovoľníckej činnosti"
                    :clearable="true"
                    :persistent-hint="true"
                    hint="Organizátor nie je povinný poskytnúť dobrovoľníkovi ubytovanie počas výkonu dobrovoľníckej činnosti."
                    indexGroupValue="volunteer_place"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="ItemForm.volunteerActivity"
                    :error-messages="errors.volunteerActivity"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Aké sú Vaše skúsenosti s dobrovoľníckou činnosťou?"
                    :persistent-hint="true"
                    hint="Popíšte prosím podujatia, oddelenia a činnosti, ktoré ste ako dobrovoľník vykonávali."
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="ItemForm.volunteerMotivation"
                    :error-messages="errors.volunteerMotivation"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Čo je Vašou motiváciou pridať sa medzi dobrovoľníkov?"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="ItemForm.characterTraits"
                    :error-messages="errors.characterTraits"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Ktoré Vaše povahové vlastnosti Vás najviac vystihujú?"
                    :persistent-hint="true"
                    hint="Popíšte sa prosím min. 3 povahovými vlastnosťami, ktoré sú Vám vlastné a vnímate ich ako prínosné na výkon dobrovoľníckej činnosti."
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-row
                    align="center"
                >
                  <v-col
                      cols="12"
                      lg="7"
                  >
                    <v-checkbox
                        v-model="ItemForm.footballClubMember"
                        :error-messages="errors.footballClubMember"
                        :dense="true"
                        hide-details="auto"
                        label="Ste členom futbalového športového klubu?"
                    />
                  </v-col>

                  <v-col
                      v-if="ItemForm.footballClubMember"
                      cols="12"
                      lg="5"
                  >
                    <v-text-field
                        v-model="ItemForm.footballClub"
                        :error-messages="errors.footballClub"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Názov klubu"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
              >
                <v-row
                    align="center"
                >
                  <v-col
                      cols="12"
                      lg="7"
                  >
                    <v-checkbox
                        v-model="ItemForm.sportClubMember"
                        :error-messages="errors.sportClubMember"
                        :dense="true"
                        hide-details="auto"
                        label="Ste členom iného športového klubu?"
                    />
                  </v-col>

                  <v-col
                      v-if="ItemForm.sportClubMember"
                      cols="12"
                      lg="5"
                  >
                    <v-text-field
                        v-model="ItemForm.sportClub"
                        :error-messages="errors.sportClub"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Názov klubu"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </CardPart>
      </v-col>

      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Pozície"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <PositionsApp/>
              </v-col>

              <v-col
                  cols="12"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.positionPrimary"
                    :error-messages="errors.positionPrimary"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Alternatíva č.1"
                    :persistent-hint="true"
                    hint="Vyberte si prosím dobrovoľnícku pozíciu, ktorú máte záujem počas majstrovstiev ako dobrovoľník vykonávať."
                    indexGroupValue="volunteer_position"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.positionSecondary"
                    :error-messages="errors.positionSecondary"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Alternatíva č.2"
                    :persistent-hint="true"
                    hint="Vyberte si prosím dobrovoľnícku pozíciu, ktorú máte záujem počas majstrovstiev ako dobrovoľník vykonávať."
                    indexGroupValue="volunteer_position"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.positionTertiary"
                    :error-messages="errors.positionTertiary"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Alternatíva č.3"
                    :persistent-hint="true"
                    hint="Vyberte si prosím dobrovoľnícku pozíciu, ktorú máte záujem počas majstrovstiev ako dobrovoľník vykonávať."
                    indexGroupValue="volunteer_position"
                />
              </v-col>
            </v-row>
          </template>
        </CardPart>
      </v-col>

      <v-col
          cols="12"
      >
        <v-textarea
            v-model="ItemForm.other"
            :error-messages="errors.other"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Ostatné informácie"
            :persistent-hint="true"
            hint="Uveďte prosím ostatné informácie, ktoré neboli pokryté v predchádzajúcich poliach a vnímate ich ako dôležité."
            :rows="2"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <template
            v-if="Item.status !== 'locked'"
        >
          <a
              class="error--text"
              @click.stop.prevent="$refs.setStatusVolunteerLocked.$el.click()"
          >
            <v-icon
                color="error"
            >
              mdi-cancel
            </v-icon>

            Chcem zrušiť registráciu dobrovoľníka
          </a>

          <ProfileSetStatusVolunteerLocked
              ref="setStatusVolunteerLocked"
              class="d-none"
              @onProfileSetStatusVolunteerLocked="(response) => {
                close()

                onProfileSetVolunteer(response)
              }"
          />
        </template>

        <template
            v-if="Item.status === 'locked'"
        >
          <a
              class="success--text"
              @click.stop.prevent="$refs.setStatusVolunteerUnlocked.$el.click()"
          >
            <v-icon
                color="success"
            >
              mdi-restore
            </v-icon>

            Chcem obnoviť registráciu dobrovoľníka
          </a>

          <ProfileSetStatusVolunteerUnlocked
              ref="setStatusVolunteerUnlocked"
              class="d-none"
              @onProfileSetStatusVolunteerUnlocked="(response) => {
                close()

                onProfileSetVolunteer(response)
              }"
          />
        </template>
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Profile from '@/services/profile'

  import File from '@/components/_App/_Modal/_Save/File'
  import ProfileSetStatusVolunteerLocked from '@/components/_App/_Modal/_Save/ProfileSetStatusVolunteerLocked'
  import ProfileSetStatusVolunteerUnlocked from '@/components/_App/_Modal/_Save/ProfileSetStatusVolunteerUnlocked'

  import PositionsApp from '@/components/_App/Positions'

  import SaveModal from '@/components/_Part/_Modal/Save'

  import CardPart from '@/components/_Part/Card'

  import ComboboxRegisterIndexesIndexGroupValue from '@/components/Register/ComboboxIndexesIndexGroupValue'
  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'ProfileSetVolunteer',
    components: {
      File,
      ProfileSetStatusVolunteerLocked,
      ProfileSetStatusVolunteerUnlocked,
      PositionsApp,
      SaveModal,
      CardPart,
      ComboboxRegisterIndexesIndexGroupValue,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        color: 'warning',
        icon: 'mdi-pencil',
        maxWidth: 750,
        buttonYes: 'Nastaviť',
        itemKey: 'User',
        photo: null,
        languageSkills: [],
        pcSkills: []
      }
    },
    computed: {
      languageTypes: function() {
        return this.languageSkills.map((_languageSkill) => _languageSkill.type)
      },
      languageLevels: function() {
        return this.languageSkills.map((_languageSkill) => _languageSkill.level)
      },
      pcTypes: function() {
        return this.pcSkills.map((_pcSkill) => _pcSkill.type)
      },
      pcLevels: function() {
        return this.pcSkills.map((_pcSkill) => _pcSkill.level)
      }
    },
    methods: {
      fetchData() {
        return Profile
          .exist()
      },
      setTitle() {
        return 'Nastaviť profil'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setItemForm(Item) {
        this.photo = Item.photoToken
        this.languageSkills = Item.languageSkills
        this.pcSkills = Item.pcSkills

        return {
          Photo: Item.photoId,
          username: Item.username,
          gender: Item.gender,
          firstName: Item.firstName,
          lastName: Item.lastName,
          email: Item.email,
          phone: Item.phone,
          birthDate: Item.birthDatePlain,
          idCard: Item.idCard,
          shirtSize: Item.shirtSize,
          shortSize: Item.shortSize,
          iban: Item.iban,
          residenceStreet: Item.residenceStreet,
          residenceCity: Item.residenceCity,
          residenceZip: Item.residenceZip,
          residenceCountry: Item.residenceCountry,
          correspondenceAnotherAsResidence: Item.correspondenceAnotherAsResidence,
          correspondenceStreet: Item.correspondenceStreet,
          correspondenceCity: Item.correspondenceCity,
          correspondenceZip: Item.correspondenceZip,
          correspondenceCountry: Item.correspondenceCountry,
          parentFirstName: Item.parentFirstName,
          parentLastName: Item.parentLastName,
          parentEmail: Item.parentEmail,
          parentPhone: Item.parentPhone,
          parentBirthDate: Item.parentBirthDatePlain,
          parentIdCard: Item.parentIdCard,
          parentResidenceAnotherAsResidence: Item.parentResidenceAnotherAsResidence,
          parentResidenceStreet: Item.parentResidenceStreet,
          parentResidenceCity: Item.parentResidenceCity,
          parentResidenceZip: Item.parentResidenceZip,
          parentResidenceCountry: Item.parentResidenceCountry,
          parentCorrespondenceAnotherAsResidence: Item.parentCorrespondenceAnotherAsResidence,
          parentCorrespondenceStreet: Item.parentCorrespondenceStreet,
          parentCorrespondenceCity: Item.parentCorrespondenceCity,
          parentCorrespondenceZip: Item.parentCorrespondenceZip,
          parentCorrespondenceCountry: Item.parentCorrespondenceCountry,
          emergencyFirstName: Item.emergencyFirstName,
          emergencyLastName: Item.emergencyLastName,
          emergencyPhone: Item.emergencyPhone,
          education: Item.education,
          school: Item.school,
          employment: Item.employment,
          placePrimary: Item.placePrimary,
          placeSecondary: Item.placeSecondary,
          volunteerActivity: Item.volunteerActivity,
          volunteerMotivation: Item.volunteerMotivation,
          characterTraits: Item.characterTraits,
          footballClubMember: Item.footballClubMember,
          footballClub: Item.footballClub,
          sportClubMember: Item.sportClubMember,
          sportClub: Item.sportClub,
          positionPrimary: Item.positionPrimary,
          positionSecondary: Item.positionSecondary,
          positionTertiary: Item.positionTertiary,
          other: Item.other
        }
      },
      profileSetVolunteerData(ItemForm) {
        return Profile
          .set({
            data: {
              ...ItemForm,
              languageSkills: this.languageSkills,
              pcSkills: this.pcSkills
            }
          })
      },
      onProfileSetVolunteer() {
        this.$store.dispatch('auth/check')
      }
    }
  }
</script>
