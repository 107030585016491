<template>
  <NotBlankPage
      :maxWidth="maxWidth"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:action="{
          Item,
          reload
        }"
    >
      <Update
          v-if="Item.updatable"
          :id="Item.id"
          @onUpdate="reload"
      />

      <UpdateStatusCanceled
          v-if="Item.status !== 'canceled'"
          :id="Item.id"
          @onUpdateStatusCanceled="reload"
      />

      <Delete
          v-if="Item.deletable"
          :id="Item.id"
          @onDelete="() => {
            $router.push('/events')
          }"
      />
    </template>

    <template
        v-slot:default="{
          calls,
          Item,
          reload
        }"
    >
      <v-row>
        <v-col
            v-if="Item.content"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <div
                  v-html="Item.content"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <Applications
              :key="calls"
              :Event="Item"
              @reload="reload"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Events from '@/services/events'

  import Update from '@/components/Events/Update'
  import UpdateStatusCanceled from '@/components/Events/UpdateStatusCanceled'
  import Delete from '@/components/Events/Delete'

  import Applications from '@/components/Event/Applications'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Event',
    components: {
      Update,
      UpdateStatusCanceled,
      Delete,
      Applications,
      NotBlankPage,
      CardPart
    },
    data() {
      return {
        maxWidth: 'calc(100% - 212px)',
        icon: 'mdi-application',
        Item: 'Event',
        failLink: '/events'
      }
    },
    methods: {
      fetchData() {
        return Events
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.adminInitials + ' | ' + Item.title + ' | ' + this.$moment(Item.dateFromPlain).format('DD.MM.YYYY') + (Item.multipleDays ? ' - ' + this.$moment(Item.dateToPlain).format('DD.MM.YYYY') : '' ) + ' | ' + Item.startPlain + ' - ' + Item.endPlain
      },
      setSubtitle() {
        return 'Udalosť'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Udalosti',
            to: '/events',
            exact: true
          }
        ]
      }
    }
  }
</script>
