<template>
  <GridPart
      :icon="icon"
      :title="title"
      :colspan="colspan"
      :empty="empty"
      :defaultOrder="defaultOrder"
      :defaultBy="defaultBy"
      :defaultLimit="defaultLimit"
      :fetchData="fetchData"
      :itemsKey="itemsKey"
  >
    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-col
          cols="12"
      >
        <v-text-field
            v-model="params.query"
            :error-messages="errors.query"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Vyhľadávanie"
            :clearable="true"
            @change="setSearch"
        />
      </v-col>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          setOrderBy
        }"
    >
      <th
          class="text-left fixed"
      >
        <OrderByList
            label="Dátum vykonania"
            value="attdat.createdAt"
            :order="order"
            :by="by"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Výsledné hodnotenie
      </th>

      <th>
        Výsledok
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item
        }"
    >
      <td
          class="text-left fixed"
      >
        {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
      </td>

      <td>
        {{ Item.resultRate|percent }}
      </td>

      <td>
        <v-icon
            v-if="Item.resultSuccessful"
            color="success"
        >
          mdi-check
        </v-icon>

        <v-icon
            v-else
            color="error"
        >
          mdi-close
        </v-icon>
      </td>

      <td
          class="text-right fixed"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="primary"
            :to="'/attempts-auth/' + Item.id"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </td>
    </template>

    <template
        v-slot:pagination-count="{
          limit,
          setLimit
        }"
    >
      <LimitPagination
          v-for="(_limit, i) in [10, 25, 50, 100]"
          :key="i"
          :label="_limit"
          :value="_limit"
          :limit="limit"
          @setLimit="setLimit"
      />
    </template>
  </GridPart>
</template>

<script>
  import AttemptsAuth from '@/services/attempts-auth'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  export default {
    name: 'Attempts',
    props: {
      Quizz: {
        type: Object,
        required: true
      }
    },
    components: {
      GridPart,
      OrderByList,
      LimitPagination
    },
    data() {
      return {
        icon: 'mdi-ab-testing',
        title: 'Pokusy',
        colspan: 4,
        empty: 'Nenašli sa žiadne pokusy.',
        itemsKey: 'attempts',
        defaultOrder: 'attdat.createdAt',
        defaultBy: 'DESC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return AttemptsAuth
          .searchQuizz({
            quizzId: this.Quizz.id,
            params: params
          })
      }
    }
  }
</script>
