import Vue from 'vue'

import Qs from 'qs'

class Applications {
  async searchEvent({eventId, params}) {
    return await Vue.axios
      .get('app/applications/search/by-event/' + eventId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async searchVolunteer({volunteerId, params}) {
    return await Vue.axios
      .get('app/applications/search/by-volunteer/' + volunteerId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/applications/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/applications/' + id, data)
  }

  async updateStatusApproved({id, data}) {
    return await Vue.axios
      .put('app/applications/' + id + '/status/approved', data)
  }

  async updateStatusRejected({id, data}) {
    return await Vue.axios
      .put('app/applications/' + id + '/status/rejected', data)
  }
}

const applications = new Applications()

export default applications
