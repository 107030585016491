<template>
  <UpdateSave
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="onUpdate"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <AutocompleteIndexGroups
            v-model="ItemForm.IndexGroup"
            :error-messages="errors.IndexGroup"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Skupina číselníkov"
        />
      </v-col>

      <v-col
          cols="12"
          sm="8"
      >
        <v-text-field
            v-model="ItemForm.title"
            :error-messages="errors.title"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Názov"
        />
      </v-col>

      <v-col
          cols="12"
          sm="4"
      >
        <v-text-field
            v-model="ItemForm.sort"
            :error-messages="errors.sort"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Poradie"
            type="number"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.value"
            :error-messages="errors.value"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Hodnota"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <vue-editor
            v-model="ItemForm.content"
        />
      </v-col>
    </template>
  </UpdateSave>
</template>

<script>
  import Indexes from '@/services/indexes'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import AutocompleteIndexGroups from '@/components/IndexGroups/Autocomplete'

  export default {
    name: 'Update',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      AutocompleteIndexGroups
    },
    data() {
      return {
        maxWidth: 750,
        itemKey: 'Index'
      }
    },
    methods: {
      fetchData() {
        return Indexes
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Upraviť číselník'
      },
      setSubtitle(Item) {
        return Item.indexGroupTitle + ' | ' + Item.title
      },
      setItemForm(Item) {
        return {
          IndexGroup: Item.indexGroupId,
          title: Item.title,
          value: Item.value,
          content: Item.content,
          sort: Item.sort
        }
      },
      updateData(ItemForm) {
        return Indexes
          .update({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
