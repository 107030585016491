var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UpdateSave',{attrs:{"maxWidth":_vm.maxWidth,"fetchData":_vm.fetchData,"itemKey":_vm.itemKey,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setItemForm":_vm.setItemForm,"updateData":_vm.updateData},on:{"onUpdate":_vm.onUpdate},scopedSlots:_vm._u([{key:"default",fn:function({
        Item,
        close,
        errors,
        ItemForm
      }){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":errors.position,"filled":true,"dense":true,"hide-details":"auto","label":"Pozícia"},model:{value:(ItemForm.position),callback:function ($$v) {_vm.$set(ItemForm, "position", $$v)},expression:"ItemForm.position"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"error-messages":errors.attendanceRecord,"dense":true,"hide-details":"auto","label":"Zúčastnil/a sa udalosti"},model:{value:(ItemForm.attendanceRecord),callback:function ($$v) {_vm.$set(ItemForm, "attendanceRecord", $$v)},expression:"ItemForm.attendanceRecord"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"error-messages":errors.note,"filled":true,"dense":true,"hide-details":"auto","label":"Poznámka","rows":2},model:{value:(ItemForm.note),callback:function ($$v) {_vm.$set(ItemForm, "note", $$v)},expression:"ItemForm.note"}})],1),(['in-queue', 'rejected'].includes(Item.status))?_c('v-col',{attrs:{"cols":"12"}},[_c('a',{staticClass:"success--text",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.updateStatusApproved.$el.click()}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]),_vm._v(" Chcem schváliť prihlášku ")],1),_c('UpdateStatusApproved',{ref:"updateStatusApproved",staticClass:"d-none",attrs:{"id":Item.id},on:{"onUpdateStatusApproved":(response) => {
            close()

            _vm.onUpdate(response)
          }}})],1):_vm._e(),(['in-queue', 'approved'].includes(Item.status))?_c('v-col',{attrs:{"cols":"12"}},[_c('a',{staticClass:"error--text",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.updateStatusRejected.$el.click()}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-cancel ")]),_vm._v(" Chcem odmietnuť prihlášku ")],1),_c('UpdateStatusRejected',{ref:"updateStatusRejected",staticClass:"d-none",attrs:{"id":Item.id},on:{"onUpdateStatusRejected":(response) => {
            close()

            _vm.onUpdate(response)
          }}})],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }