<template>
  <DeleteConfirm
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :deleteData="deleteEventData"
      @onDelete="onDeleteEvent"
  />
</template>

<script>
  import ApplicationsAuth from '@/services/applications-auth'

  import DeleteConfirm from '@/components/_Part/_Modal/_Save/_Confirm/Delete'

  export default {
    name: 'DeleteEvent',
    props: {
      eventId: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onDeleteEvent'
    ],
    components: {
      DeleteConfirm
    },
    data() {
      return {
        itemKey: 'Course'
      }
    },
    methods: {
      fetchData() {
        return ApplicationsAuth
          .readEvent({
            eventId: this.eventId
          })
      },
      setTitle() {
        return 'Zrušiť registráciu na udalosť'
      },
      setSubtitle(Item) {
        return Item.eventTitle
      },
      setAlert() {
        return 'Naozaj chcete zrušiť registráciu na udalosť?'
      },
      deleteEventData() {
        return ApplicationsAuth
          .deleteEvent({
            eventId: this.eventId
          })
      },
      onDeleteEvent(response) {
        this.$emit('onDeleteEvent', response)
      }
    }
  }
</script>
