var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotBlankPage',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"fetchData":_vm.fetchData,"itemKey":_vm.Item,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs,"failLink":_vm.failLink},scopedSlots:_vm._u([{key:"action",fn:function({
        Item,
        reload
      }){return [(Item.updatable)?_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":reload}}):_vm._e(),(Item.status !== 'canceled')?_c('UpdateStatusCanceled',{attrs:{"id":Item.id},on:{"onUpdateStatusCanceled":reload}}):_vm._e(),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":() => {
          _vm.$router.push('/events')
        }}}):_vm._e()]}},{key:"default",fn:function({
        calls,
        Item,
        reload
      }){return [_c('v-row',[(Item.content)?_c('v-col',{attrs:{"cols":"12"}},[_c('CardPart',{staticClass:"grey lighten-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(Item.content)}})]},proxy:true}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('Applications',{key:calls,attrs:{"Event":Item},on:{"reload":reload}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }