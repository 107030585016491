<template>
  <UpdateSave
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="onUpdate"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.title"
            :error-messages="errors.title"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Názov"
        />
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.place"
            :error-messages="errors.place"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Miesto"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <vue-editor
            v-model="ItemForm.content"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.dateFrom"
            :error-messages="errors.dateFrom"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Od"
            type="date"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-row
            align="center"
        >
          <v-col
              cols="12"
              sm="6"
          >
            <v-checkbox
                v-model="ItemForm.multipleDays"
                :error-messages="errors.multipleDays"
                :dense="true"
                hide-details="auto"
                label="Viac dní"
            />
          </v-col>

          <v-col
              v-if="ItemForm.multipleDays"
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="ItemForm.dateTo"
                :error-messages="errors.dateTo"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Do"
                type="date"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.start"
            :error-messages="errors.start"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Začiatok"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.end"
            :error-messages="errors.end"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Koniec"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.deadline"
            :error-messages="errors.deadline"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Uzávierka"
            type="datetime-local"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="ItemForm.privacy"
            :error-messages="errors.privacy"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Typ"
            indexGroupValue="event_privacy"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-checkbox
            v-model="ItemForm.agreementTermEmail"
            :error-messages="errors.agreementTermEmail"
            :dense="true"
            hide-details="auto"
            label="Chcem odoslať e-mail 'Zmena termínu udalosti'"
        />
      </v-col>
    </template>
  </UpdateSave>
</template>

<script>
  import Events from '@/services/events'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Update',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 750,
        itemKey: 'Event'
      }
    },
    methods: {
      fetchData() {
        return Events
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Upraviť udalosť'
      },
      setSubtitle(Item) {
        return Item.adminInitials + ' | ' + Item.title + ' | ' + this.$moment(Item.dateFromPlain).format('DD.MM.YYYY') + (Item.multipleDays ? ' - ' + this.$moment(Item.dateToPlain).format('DD.MM.YYYY') : '' ) + ' | ' + Item.startPlain + ' - ' + Item.endPlain
      },
      setItemForm(Item) {
        return {
          title: Item.title,
          place: Item.place,
          content: Item.content,
          multipleDays: Item.multipleDays,
          dateFrom: Item.dateFromPlain,
          dateTo: Item.dateToPlain,
          start: Item.startPlain,
          end: Item.endPlain,
          deadline: Item.deadlinePlain,
          privacy: Item.privacy,
          agreementTermEmail: false
        }
      },
      updateData(ItemForm) {
        return Events
          .update({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
