<template>
  <ConfirmSave
      :color="color"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :confirmData="createEventData"
      @onConfirm="onCreateEvent"
  />
</template>

<script>
  import EventsAuth from '@/services/events-auth'
  import ApplicationsAuth from '@/services/applications-auth'

  import ConfirmSave from '@/components/_Part/_Modal/_Save/Confirm'

  export default {
    name: 'CreateEvent',
    props: {
      eventId: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onCreateEvent'
    ],
    components: {
      ConfirmSave
    },
    data() {
      return {
        color: 'success',
        icon: 'mdi-pen',
        itemKey: 'Event'
      }
    },
    methods: {
      fetchData() {
        return EventsAuth
          .read({
            id: this.eventId
          })
      },
      setTitle() {
        return 'Zaregistrovať sa na udalosť'
      },
      setSubtitle(Item) {
        return Item.title
      },
      setAlert() {
        return 'Naozaj sa chcete zaregistrovať na udalosť?'
      },
      createEventData() {
        return ApplicationsAuth
          .createEvent({
            eventId: this.eventId,
            data: {}
          })
      },
      onCreateEvent(response) {
        this.$emit('onCreateEvent', response)
      }
    }
  }
</script>
