var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('CardPart',{attrs:{"icon":_vm.icon,"title":title},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"outlined":true,"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.viewMonth.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.calendar,'MM/YY'))+" ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-calendar',{ref:"calendar",attrs:{"locale":"sk","type":_vm.type,"weekdays":[1, 2, 3, 4, 5, 6, 0],"events":_vm.items,"event-start":"from","event-end":"to","event-height":52,"event-margin-bottom":2,"interval-height":160},on:{"click:date":_vm.viewDay,"change":_vm.fetchData},scopedSlots:_vm._u([{key:"day-header",fn:function({
                    date
                  }){return [(_vm.$calculate.eventCreate(date))?_c('div',{staticClass:"v-calendar-area head"},[_c('CreateDate',{attrs:{"date":date},on:{"onCreateDate":_vm.refreshData}}),_c('CreateMoreDate',{attrs:{"date":date},on:{"onCreateMoreDate":_vm.refreshData}})],1):_vm._e()]}},{key:"day",fn:function({
                    date
                  }){return [(_vm.$calculate.eventCreate(date))?_c('div',{staticClass:"v-calendar-area head"},[_c('CreateDate',{attrs:{"date":date},on:{"onCreateDate":_vm.refreshData}}),_c('CreateMoreDate',{attrs:{"date":date},on:{"onCreateMoreDate":_vm.refreshData}})],1):_vm._e()]}},{key:"event",fn:function({
                    event
                  }){return [_c('div',{staticClass:"v-calendar-area"},[_vm._v(" "+_vm._s(event.adminInitials)+" | "+_vm._s(event.startPlain)+" - "+_vm._s(event.endPlain)+" "),(_vm.type === 'day')?[(event.volunteerName)?[_vm._v(" - "+_vm._s(event.volunteerName)+" ")]:_vm._e()]:_vm._e(),(event.note)?_c('v-tooltip',{attrs:{"bottom":true},scopedSlots:_vm._u([{key:"activator",fn:function({
                          on,
                          attrs
                        }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(event.note)+" ")]):_vm._e(),_c('br'),(event.url)?_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"default","href":event.url,"target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-video ")])],1):_vm._e(),(event.updatable)?_c('Update',{attrs:{"id":event.id},on:{"onUpdate":_vm.refreshData}}):_vm._e(),(event.status === 'reserved')?[_c('UpdateStatusClosed',{attrs:{"id":event.id},on:{"onUpdateStatusClosed":_vm.refreshData}}),_c('UpdateStatusCanceled',{attrs:{"id":event.id},on:{"onUpdateStatusCanceled":_vm.refreshData}})]:_vm._e(),(event.deletable)?_c('Delete',{attrs:{"x-small":true,"id":event.id},on:{"onDelete":_vm.refreshData}}):_vm._e()],2)]}}],null,true),model:{value:(_vm.calendar),callback:function ($$v) {_vm.calendar=$$v},expression:"calendar"}})],1)],1)]},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }