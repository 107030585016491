<template>
  <UpdateSave
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="onUpdate"
  >
    <template
        v-slot:default="{
          Item,
          close,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.position"
            :error-messages="errors.position"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Pozícia"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-checkbox
            v-model="ItemForm.attendanceRecord"
            :error-messages="errors.attendanceRecord"
            :dense="true"
            hide-details="auto"
            label="Zúčastnil/a sa udalosti"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-textarea
            v-model="ItemForm.note"
            :error-messages="errors.note"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Poznámka"
            :rows="2"
        />
      </v-col>

      <v-col
          v-if="['in-queue', 'rejected'].includes(Item.status)"
          cols="12"
      >
        <a
            class="success--text"
            @click.stop.prevent="$refs.updateStatusApproved.$el.click()"
        >
          <v-icon
              color="success"
          >
            mdi-check
          </v-icon>

          Chcem schváliť prihlášku
        </a>

        <UpdateStatusApproved
            ref="updateStatusApproved"
            :id="Item.id"
            class="d-none"
            @onUpdateStatusApproved="(response) => {
              close()

              onUpdate(response)
            }"
        />
      </v-col>

      <v-col
          v-if="['in-queue', 'approved'].includes(Item.status)"
          cols="12"
      >
        <a
            class="error--text"
            @click.stop.prevent="$refs.updateStatusRejected.$el.click()"
        >
          <v-icon
              color="error"
          >
            mdi-cancel
          </v-icon>

          Chcem odmietnuť prihlášku
        </a>

        <UpdateStatusRejected
            ref="updateStatusRejected"
            :id="Item.id"
            class="d-none"
            @onUpdateStatusRejected="(response) => {
              close()

              onUpdate(response)
            }"
        />
      </v-col>
    </template>
  </UpdateSave>
</template>

<script>
  import Applications from '@/services/applications'

  import UpdateStatusApproved from '@/components/Applications/UpdateStatusApproved'
  import UpdateStatusRejected from '@/components/Applications/UpdateStatusRejected'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'Update',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateStatusApproved,
      UpdateStatusRejected,
      UpdateSave
    },
    data() {
      return {
        maxWidth: 500,
        itemKey: 'Application'
      }
    },
    methods: {
      fetchData() {
        return Applications
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Upraviť prihlášku'
      },
      setSubtitle(Item) {
        return Item.volunteerName
      },
      setItemForm(Item) {
        return {
          position: Item.position,
          attendanceRecord: Item.attendanceRecord,
          note: Item.note
        }
      },
      updateData(ItemForm) {
        return Applications
          .update({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
