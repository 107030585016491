var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"title":title,"hasFilter":_vm.hasFilter,"colspan":_vm.colspan,"empty":_vm.empty,"defaultFilters":_vm.defaultFilters,"defaultOrder":_vm.defaultOrder,"defaultBy":_vm.defaultBy,"defaultLimit":_vm.defaultLimit,"fetchData":_vm.fetchData,"itemsKey":_vm.itemsKey},scopedSlots:_vm._u([{key:"action",fn:function({
            init
          }){return [_c('Create',{on:{"onCreate":init}})]}},{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"9"}},[_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})],1)]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('AutocompleteRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.status,"outlined":true,"dense":true,"hide-details":"auto","label":"Stav","clearable":true,"indexGroupValue":"course_status"},on:{"change":setFilter},model:{value:(params.Filters.status),callback:function ($$v) {_vm.$set(params.Filters, "status", $$v)},expression:"params.Filters.status"}})],1)]}},{key:"list-head",fn:function({
            order,
            by,
            setOrderBy
          }){return [_c('th',{staticClass:"text-left fixed"},[_c('OrderByList',{attrs:{"label":"Názov","value":"coudat.title","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Stav ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item,
            init
          }){return [_c('td',{staticClass:"text-left fixed"},[_c('TruncateHelper',{attrs:{"title":Item.title,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.title)+" ")])],1),_c('td',[_c('v-badge',{attrs:{"inline":true,"color":Item.statusColorEnum,"content":Item.statusEnum}})],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/courses/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_c('v-badge',{attrs:{"color":"info","value":Item.quizzesTotal,"content":Item.quizzesTotal}})],1),_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":init}}),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":init}}):_vm._e()],1)]}},{key:"pagination-count",fn:function({
            limit,
            setLimit
          }){return _vm._l(([10, 25, 50, 100]),function(_limit,i){return _c('LimitPagination',{key:i,attrs:{"label":_limit,"value":_limit,"limit":limit},on:{"setLimit":setLimit}})})}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }