import Vue from 'vue'

class Statistics {
  async volunteersByPlaces() {
    return await Vue.axios
      .get('app/statistics/volunteers-by-places')
  }

  async volunteersByStatuses() {
    return await Vue.axios
      .get('app/statistics/volunteers-by-statuses')
  }

  async volunteersByPositions() {
    return await Vue.axios
      .get('app/statistics/volunteers-by-positions')
  }

  async volunteersForPlaceByPositions({placeId}) {
    return await Vue.axios
      .get('app/statistics/volunteers-for-place-by-positions/' + placeId)
  }
}

const statistics = new Statistics()

export default statistics
