<template>
  <!-- TODO -->
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          :icon="icon"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="auto"
                >
                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="$refs.calendar.prev()"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-spacer/>

                <v-col
                    cols="auto"
                >
                  <v-btn
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="viewMonth"
                  >
                    {{ calendar|moment('MM/YY') }}
                  </v-btn>
                </v-col>

                <v-spacer/>

                <v-col
                    cols="auto"
                >
                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="$refs.calendar.next()"
                  >
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
            >
              <v-calendar
                  v-model="calendar"
                  ref="calendar"
                  locale="sk"
                  :type="type"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  :events="items"
                  event-start="from"
                  event-end="to"
                  :event-height="78"
                  :event-margin-bottom="2"
                  :interval-height="158"
                  @click:date="viewDay"
                  @change="fetchData"
              >
                <template
                    v-slot:day-header="{
                      date
                    }"
                >
                  <div
                      v-if="$calculate.eventCreate(date)"
                      class="v-calendar-area head"
                  >
                    <CreateDateFrom
                        :dateFrom="date"
                        @onCreateDateFrom="refreshData"
                    />
                  </div>
                </template>

                <template
                    v-slot:day="{
                      date
                    }"
                >
                  <div
                      v-if="$calculate.eventCreate(date)"
                      class="v-calendar-area head"
                  >
                    <CreateDateFrom
                        :dateFrom="date"
                        @onCreateDateFrom="refreshData"
                    />
                  </div>
                </template>

                <template
                    v-slot:event="{
                      event
                    }"
                >
                  <div
                      class="v-calendar-area"
                  >
                    {{ event.adminInitials }} | {{ event.title }}

                    <br/>

                    {{ event.startPlain }} - {{ event.endPlain }}

                    <v-tooltip
                        v-if="event.privacy === 'private'"
                        :bottom="true"
                    >
                      <template
                          v-slot:activator="{
                            on,
                            attrs
                          }"
                      >
                        <span
                            v-on="on"
                            v-bind="attrs"
                        >
                          <v-icon>
                            mdi-incognito-circle
                          </v-icon>
                        </span>
                      </template>

                      {{ event.privacyEnum }}
                    </v-tooltip>

                    <br/>

                    <v-btn
                        :icon="true"
                        :outlined="true"
                        color="primary"
                        :to="'/events/' + event.id"
                    >
                      <v-icon>
                        mdi-magnify
                      </v-icon>

                      <v-badge
                          color="info"
                          :value="event.applicationsTotal"
                          :content="event.applicationsTotal"
                      />
                    </v-btn>

                    <Update
                        v-if="event.updatable"
                        :id="event.id"
                        @onUpdate="refreshData"
                    />

                    <UpdateStatusCanceled
                        v-if="event.status !== 'canceled'"
                        :id="event.id"
                        @onUpdateStatusCanceled="refreshData"
                    />

                    <Delete
                        v-if="event.deletable"
                        :x-small="true"
                        :id="event.id"
                        @onDelete="refreshData"
                    />
                  </div>
                </template>
              </v-calendar>
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import Events from '@/services/events'

  import CreateDateFrom from '@/components/Events/CreateDateFrom'
  import Update from '@/components/Events/Update'
  import UpdateStatusCanceled from '@/components/Events/UpdateStatusCanceled'
  import Delete from '@/components/Events/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Events',
    components: {
      CreateDateFrom,
      Update,
      UpdateStatusCanceled,
      Delete,
      BlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-application',
        calendar: this.$moment().toISOString(),
        start: null,
        end: null,
        type: 'month',
        events: []
      }
    },
    computed: {
      items: function () {
        return this.events.map((_Event) => ({
          ..._Event,
          name: _Event.statusEnum,
          from: _Event.dateFromPlain + ' ' + _Event.startPlain,
          to: _Event.dateToPlain + ' ' + _Event.endPlain,
          color: _Event.statusColorEnum
        }))
      }
    },
    methods: {
      setTitle() {
        return 'Udalosti'
      },
      viewMonth() {
        this.type = 'month'
      },
      viewDay({date}) {
        this.calendar = date

        this.type = 'day'
      },
      refreshData() {
        this.fetchData({
          start: this.start,
          end: this.end
        })
      },
      fetchData({start, end}) {
        this.start = start

        this.end = end

        Events
          .indexStartAndEnd({
            start: start.date,
            end: end.date
          })
          .then((response) => {
            this.events = response.data.data.events
          })
          .catch((error) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: error.response.data.message
            })
          })
      }
    }
  }
</script>
