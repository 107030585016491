import Vue from 'vue'

class Events {
  async indexStartAndEnd({start, end}) {
    return await Vue.axios
      .get('app/events/by-start-and-end/' + start + '/' + end)
  }

  async createDateFrom({dateFrom, data}) {
    return await Vue.axios
      .post('app/events/by-date-from/' + dateFrom, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/events/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/events/' + id, data)
  }

  async updateStatusCanceled({id, data}) {
    return await Vue.axios
      .put('app/events/' + id + '/status/canceled', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/events/' + id)
  }
}

const events = new Events()

export default events
