<template>
  <NotBlankPage
      :maxWidth="maxWidth"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <Update
          :id="Item.id"
          @onUpdate="refresh"
      />

      <Delete
          v-if="Item.deletable"
          :id="Item.id"
          @onDelete="() => {
            $router.push('/volunteers')
          }"
      />
    </template>

    <template
        v-slot:default="{
          calls,
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    E-mail
                  </h3>

                  <p>
                    <a
                        :href="'mailto: ' + Item.email"
                    >
                      {{ Item.email }}
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Telefón
                  </h3>

                  <p>
                    <a
                        :href="'tel: ' + Item.phone"
                    >
                      {{ Item.phone }}
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Dátum narodenia
                  </h3>

                  <p>
                    {{ Item.birthDatePlain|moment('DD.MM.YYYY') }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Primárne miesto
                  </h3>

                  <p>
                    {{ Item.placePrimaryEnum }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Sekundárne miesto
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.placeSecondaryEnum"
                  >
                    {{ Item.placeSecondaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Alternatíva č.1
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.positionPrimaryEnum"
                  >
                    {{ Item.positionPrimaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Alternatíva č.2
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.positionSecondaryEnum"
                  >
                    {{ Item.positionSecondaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Alternatíva č.3
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.positionTertiaryEnum"
                  >
                    {{ Item.positionTertiaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Stav
                  </h3>

                  <p>
                    <v-badge
                        :inline="true"
                        :color="Item.statusColorEnum"
                        :content="Item.statusEnum"
                    />

                    <v-tooltip
                        v-if="Item.note"
                        :bottom="true"
                    >
                      <template
                          v-slot:activator="{
                            on,
                            attrs
                          }"
                      >
                        <span
                            v-on="on"
                            v-bind="attrs"
                        >
                          <v-icon>
                            mdi-information
                          </v-icon>
                        </span>
                      </template>

                      {{ Item.note }}
                    </v-tooltip>
                  </p>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <Attempts
              :key="calls"
              :Volunteer="Item"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <Applications
              :key="calls"
              :Volunteer="Item"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <UserHistories
              :key="calls"
              :User="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Volunteers from '@/services/volunteers'

  import Update from '@/components/Volunteers/Update'
  import Delete from '@/components/Volunteers/Delete'

  import Attempts from '@/components/Volunteer/Attempts'
  import Applications from '@/components/Volunteer/Applications'
  import UserHistories from '@/components/Volunteer/UserHistories'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  import ParagraphIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Paragraph'

  export default {
    name: 'Volunteer',
    components: {
      Update,
      Delete,
      Attempts,
      Applications,
      UserHistories,
      NotBlankPage,
      CardPart,
      ParagraphIsEmpty
    },
    data() {
      return {
        maxWidth: 'calc(100% - 168px)',
        icon: 'mdi-handshake',
        Item: 'Volunteer',
        failLink: '/volunteers'
      }
    },
    methods: {
      fetchData() {
        return Volunteers
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.name
      },
      setSubtitle() {
        return 'Dobrovoľník'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Dobrovoľníci',
            to: '/volunteers',
            exact: true
          }
        ]
      }
    }
  }
</script>
