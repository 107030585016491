<template>
  <ChartPart
      :maxWidth="maxWidth"
      :title="title"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setOptions="setOptions"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <div>
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="placeId"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Miesto"
            :clearable="true"
            @change="init"
            itemValue="id"
            indexGroupValue="volunteer_place"
        />
      </div>

      &nbsp;
    </template>
  </ChartPart>
</template>

<script>
  import Statistics from '@/services/statistics'

  import ChartPart from '@/components/_Part/Chart'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'StatisticsVolunteersForPlaceByPositions',
    components: {
      ChartPart,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 'calc(100% - 332px)',
        title: 'Počet dobrovoľníkov podľa pozície',
        itemKey: 'indexes',
        placeId: null
      }
    },
    methods: {
      fetchData() {
        if (this.placeId) {
          return Statistics
            .volunteersForPlaceByPositions({
              placeId: this.placeId
            })
        } else {
          return Statistics
            .volunteersByPositions()
        }
      },
      setOptions(items) {
        return {
          chart: {
            type: 'column'
          },
          credits: {
            enabled: false
          },
          title: {
            text: null
          },
          subtitle: {
            text: null
          },
          xAxis: {
            type: 'category',
            title: {
              text: 'Pozícia'
            }
          },
          yAxis: {
            min: 0,
            tickInterval: 1,
            title: {
              text: 'Počet dobrovoľníkov'
            },
            stackLabels: {
              enabled: true
            }
          },
          tooltip: {
            headerFormat: '{series.name}<br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: {point.y}x<br>'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true
              }
            }
          },
          series: [
            {
              name: 'Alternatíva č.1',
              color: '#5dbc43',
              data: items.map((Item) => ({
                name: Item.positionTitle,
                y: Item.volunteersPrimaryTotal
              }))
            },
            {
              name: 'Alternatíva č.2',
              color: '#bc4343',
              data: items.map((Item) => ({
                name: Item.positionTitle,
                y: Item.volunteersSecondaryTotal
              }))
            },
            {
              name: 'Alternatíva č.3',
              color: '#4379bc',
              data: items.map((Item) => ({
                name: Item.positionTitle,
                y: Item.volunteersTertiaryTotal
              }))
            }
          ]
        }
      }
    }
  }
</script>
