var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridPart',{attrs:{"icon":_vm.icon,"title":_vm.title,"hasFilter":_vm.hasFilter,"colspan":_vm.colspan,"empty":_vm.empty,"defaultFilters":_vm.defaultFilters,"defaultOrder":_vm.defaultOrder,"defaultBy":_vm.defaultBy,"defaultLimit":_vm.defaultLimit,"fetchData":_vm.fetchData,"itemsKey":_vm.itemsKey},scopedSlots:_vm._u([{key:"search",fn:function({
        params,
        errors,
        setSearch
      }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"9"}},[_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})],1)]}},{key:"filter",fn:function({
        params,
        errors,
        setFilter
      }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('AutocompleteRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.status,"outlined":true,"dense":true,"hide-details":"auto","label":"Stav","clearable":true,"indexGroupValue":"application_status"},on:{"change":setFilter},model:{value:(params.Filters.status),callback:function ($$v) {_vm.$set(params.Filters, "status", $$v)},expression:"params.Filters.status"}})],1)]}},{key:"list-head",fn:function({
        order,
        by,
        setOrderBy
      }){return [_c('th',{staticClass:"text-left fixed"},[_c('OrderByList',{attrs:{"label":"Dátum odoslania","value":"appdat.createdAt","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Udalosť ")]),_c('th',[_vm._v(" Pozícia ")]),_c('th',[_vm._v(" Dochádzka ")]),_c('th',[_vm._v(" Stav ")])]}},{key:"list-body-standard",fn:function({
        Item
      }){return [_c('td',{staticClass:"text-left fixed"},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_c('router-link',{attrs:{"to":'/events/' + Item.eventId}},[_c('TruncateHelper',{attrs:{"title":Item.eventTitle,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.eventTitle)+" ")])],1)],1),_c('td',[_c('SpanIsEmpty',{attrs:{"value":Item.position}},[_vm._v(" "+_vm._s(Item.position)+" ")])],1),_c('td',[(Item.attendanceRecord)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")]),(Item.note)?_c('v-tooltip',{attrs:{"bottom":true},scopedSlots:_vm._u([{key:"activator",fn:function({
              on,
              attrs
            }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(Item.note)+" ")]):_vm._e()],1),_c('td',[_c('v-badge',{attrs:{"inline":true,"color":Item.statusColorEnum,"content":Item.statusEnum}})],1)]}},{key:"pagination-count",fn:function({
        limit,
        setLimit
      }){return _vm._l(([10, 25, 50, 100]),function(_limit,i){return _c('LimitPagination',{key:i,attrs:{"label":_limit,"value":_limit,"limit":limit},on:{"setLimit":setLimit}})})}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }