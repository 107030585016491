import Vue from 'vue'

class ApplicationsAuth {
  async createEvent({eventId, data}) {
    return await Vue.axios
      .post('app/applications/auth/by-event/' + eventId, data)
  }

  async readEvent({eventId}) {
    return await Vue.axios
      .get('app/applications/auth/by-event/' + eventId)
  }

  async deleteEvent({eventId}) {
    return await Vue.axios
      .delete('app/applications/auth/by-event/' + eventId)
  }
}

const applicationsAuth = new ApplicationsAuth()

export default applicationsAuth
