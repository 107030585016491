var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotBlankPage',{attrs:{"icon":_vm.icon,"fetchData":_vm.fetchData,"itemKey":_vm.Item,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs,"failLink":_vm.failLink},scopedSlots:_vm._u([{key:"default",fn:function({
        calls,
        Item,
        reload
      }){return [_c('v-row',[(Item.content)?_c('v-col',{attrs:{"cols":"12"}},[_c('CardPart',{staticClass:"grey lighten-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(Item.content)}})]},proxy:true}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":true}}):[(_vm.Application)?[(_vm.Application.deletable)?[_c('v-btn',{attrs:{"type":"submit","large":true,"block":true,"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.deleteEvent.$el.click()}}},[_c('v-icon',[_vm._v(" mdi-cancel ")]),_vm._v(" Zrušiť ")],1),_c('DeleteEvent',{ref:"deleteEvent",staticClass:"d-none",attrs:{"eventId":Item.id},on:{"onDeleteEvent":() => {
                    reload()
                  }}})]:[_vm._v(" Stav: "),_c('v-badge',{attrs:{"inline":true,"color":_vm.Application.statusColorEnum,"content":_vm.Application.statusEnum}})]]:[_c('v-btn',{attrs:{"type":"submit","large":true,"block":true,"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.createEvent.$el.click()}}},[_c('v-icon',[_vm._v(" mdi-pen ")]),_vm._v(" Zaregistrovať sa ")],1),_c('CreateEvent',{ref:"createEvent",staticClass:"d-none",attrs:{"eventId":Item.id},on:{"onCreateEvent":() => {
                reload()
              }}})]]],2)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }