<template>
  <CreateSave
      :maxWidth="maxWidth"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :createData="createDateFromData"
      @onCreate="onCreateDateFrom"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.title"
            :error-messages="errors.title"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Názov"
        />
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.place"
            :error-messages="errors.place"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Miesto"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <vue-editor
            v-model="ItemForm.content"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-row
            align="center"
        >
          <v-col
              cols="12"
              sm="6"
          >
            <v-checkbox
                v-model="ItemForm.multipleDays"
                :error-messages="errors.multipleDays"
                :dense="true"
                hide-details="auto"
                label="Viac dní"
            />
          </v-col>

          <v-col
              v-if="ItemForm.multipleDays"
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="ItemForm.dateTo"
                :error-messages="errors.dateTo"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Do"
                type="date"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.start"
            :error-messages="errors.start"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Začiatok"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.end"
            :error-messages="errors.end"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Koniec"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.deadline"
            :error-messages="errors.deadline"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Uzávierka"
            type="datetime-local"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="ItemForm.privacy"
            :error-messages="errors.privacy"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Typ"
            indexGroupValue="event_privacy"
        />
      </v-col>
    </template>
  </CreateSave>
</template>

<script>
  import Events from '@/services/events'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'CreateDateFrom',
    props: {
      dateFrom: {
        type: String,
        required: true
      }
    },
    emits: [
      'onCreateDateFrom'
    ],
    components: {
      CreateSave,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 750
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť udalosť'
      },
      setSubtitle() {
        return this.$moment(this.dateFrom).format('DD.MM.YYYY')
      },
      setItemForm() {
        return {
          title: null,
          place: null,
          content: null,
          multipleDays: false,
          dateTo: null,
          start: null,
          end: null,
          deadline: null,
          privacy: null
        }
      },
      createDateFromData(ItemForm) {
        return Events
          .createDateFrom({
            dateFrom: this.dateFrom,
            data: ItemForm
          })
      },
      onCreateDateFrom(response) {
        this.$emit('onCreateDateFrom', response)
      }
    }
  }
</script>
