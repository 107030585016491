<template>
  <ComboboxSelect
      :fetchData="fetchData"
      itemsKey="indexes"
      v-bind="{
        itemValue: 'value',
        itemText: 'title',
        ...$attrs
      }"
      v-on="$listeners"
  />
</template>

<script>
  import Register from '@/services/register'

  import ComboboxSelect from '@/components/_Part/_Field/_Select/Combobox'

  export default {
    name: 'ComboboxIndexesIndexGroupValue',
    props: {
      indexGroupValue: {
        type: String,
        required: true
      }
    },
    components: {
      ComboboxSelect
    },
    methods: {
      fetchData() {
        return Register
          .indexesIndexGroupValue({
            indexGroupValue: this.indexGroupValue
          })
      }
    }
  }
</script>
