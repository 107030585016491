<template>
  <CardPart
      :maxWidth="maxWidth"
      :icon="icon"
      :title="title"
      :subtitle="subtitle"
  >
    <template
        v-slot:action
    >
      <v-spacer/>

      <slot
          name="action"
          :init="init"
      />

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click.stop.prevent="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </template>

    <template
        v-slot:default
    >
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <highcharts
          :options="options"
      />
    </template>
  </CardPart>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Chart',
    props: {
      maxWidth: {
        type: String,
        default: 'calc(100% - 36px)'
      },
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      subtitle: {
        type: String,
        default: null
      },
      fetchData: {
        type: Function,
        required: true
      },
      itemKey: {
        type: String,
        required: true
      },
      setOptions: {
        type: Function,
        default: () => {}
      }
    },
    emits: [
      'onSuccess',
      'onFail'
    ],
    components: {
      CardPart
    },
    data() {
      return {
        loading: false,
        total: 0,
        items: [],
        options: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        this.loading = true

        this.fetchData()
          .then((response) => {
            this.total = response.data.data.total

            this.items = response.data.data[this.itemKey]

            this.options = this.setOptions(this.items)

            this.$emit('onSuccess', response)
          })
          .catch((error) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: error.response.data.message
            })

            this.$emit('onFail', error.response)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
