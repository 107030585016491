<template>
  <GridPart
      :maxWidth="maxWidth"
      :icon="icon"
      :title="title"
      :hasFilter="hasFilter"
      :colspan="colspan"
      :empty="empty"
      :defaultFilters="defaultFilters"
      :defaultOrder="defaultOrder"
      :defaultBy="defaultBy"
      :defaultLimit="defaultLimit"
      :fetchData="fetchData"
      :itemsKey="itemsKey"
  >
    <template
        v-slot:action
    >
      <v-btn
          :icon="true"
          :outlined="true"
          color="default"
          :href="$global.axiosUrl() + 'app/applications/by-event/' + Event.id  + '/export'"
          target="_blank"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-col
          cols="12"
          sm="6"
          md="9"
      >
        <v-text-field
            v-model="params.query"
            :error-messages="errors.query"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Vyhľadávanie"
            :clearable="true"
            @change="setSearch"
        />
      </v-col>
    </template>

    <template
        v-slot:filter="{
          params,
          errors,
          setFilter
        }"
    >
      <v-col
          cols="12"
          sm="6"
          md="3"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="params.Filters.status"
            :error-messages="errors.Filters?.status"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Stav"
            :clearable="true"
            @change="setFilter"
            indexGroupValue="application_status"
        />
      </v-col>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          setOrderBy
        }"
    >
      <th
          class="text-left fixed"
      >
        <OrderByList
            label="Dátum odoslania"
            value="appdat.createdAt"
            :order="order"
            :by="by"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Dobrovoľník
      </th>

      <th>
        Pozícia
      </th>

      <th>
        Dochádzka
      </th>

      <th>
        Stav
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="text-left fixed"
      >
        {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
      </td>

      <td>
        <router-link
            :to="'/volunteers/' + Item.volunteerId"
        >
          <TruncateHelper
              :title="Item.volunteerName"
              maxWidth="300px"
          >
            {{ Item.volunteerName }}
          </TruncateHelper>
        </router-link>
      </td>

      <td>
        <SpanIsEmpty
            :value="Item.position"
        >
          {{ Item.position }}
        </SpanIsEmpty>
      </td>

      <td>
        <v-icon
            v-if="Item.attendanceRecord"
            color="success"
        >
          mdi-check
        </v-icon>

        <v-icon
            v-else
            color="error"
        >
          mdi-close
        </v-icon>

        <v-tooltip
            v-if="Item.note"
            :bottom="true"
        >
          <template
              v-slot:activator="{
                on,
                attrs
              }"
          >
            <span
                v-on="on"
                v-bind="attrs"
            >
              <v-icon>
                mdi-information
              </v-icon>
            </span>
          </template>

          {{ Item.note }}
        </v-tooltip>
      </td>

      <td>
        <v-badge
            :inline="true"
            :color="Item.statusColorEnum"
            :content="Item.statusEnum"
        />
      </td>

      <td
          class="text-right fixed"
      >
        <Update
            v-if="Item.updatable"
            :id="Item.id"
            @onUpdate="(response) => {
              init()

              $emit('reload', response)
            }"
        />
      </td>
    </template>

    <template
        v-slot:pagination-count="{
          limit,
          setLimit
        }"
    >
      <LimitPagination
          v-for="(_limit, i) in [10, 25, 50, 100]"
          :key="i"
          :label="_limit"
          :value="_limit"
          :limit="limit"
          @setLimit="setLimit"
      />
    </template>
  </GridPart>
</template>

<script>
  import Applications from '@/services/applications'

  import Update from '@/components/Applications/Update'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  import SpanIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Span'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Applications',
    props: {
      Event: {
        type: Object,
        required: true
      }
    },
    emits: [
      'reload'
    ],
    components: {
      Update,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper,
      SpanIsEmpty,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 'calc(100% - 80px)',
        icon: 'mdi-email',
        title: 'Prihlášky',
        hasFilter: true,
        colspan: 6,
        empty: 'Nenašli sa žiadne prihlášky.',
        itemsKey: 'applications',
        defaultFilters: {
          status: null
        },
        defaultOrder: 'appdat.createdAt',
        defaultBy: 'DESC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return Applications
          .searchEvent({
            eventId: this.Event.id,
            params: params
          })
      }
    }
  }
</script>
