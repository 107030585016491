import Vue from 'vue'

class EventsAuth {
  async indexStartAndEnd({start, end}) {
    return await Vue.axios
      .get('app/events-auth/by-start-and-end/' + start + '/' + end)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/events-auth/' + id)
  }
}

const eventsAuth = new EventsAuth()

export default eventsAuth
