<template>
  <ConfirmSave
      :color="color"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :confirmData="updateStatusCanceledData"
      @onConfirm="onUpdateStatusCanceled"
  />
</template>

<script>
  import Events from '@/services/events'

  import ConfirmSave from '@/components/_Part/_Modal/_Save/Confirm'

  export default {
    name: 'UpdateStatusCanceled',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdateStatusCanceled'
    ],
    components: {
      ConfirmSave
    },
    data() {
      return {
        color: 'error',
        icon: 'mdi-cancel',
        itemKey: 'Event'
      }
    },
    methods: {
      fetchData() {
        return Events
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Zrušiť udalosť'
      },
      setSubtitle(Item) {
        return Item.adminInitials + ' | ' + Item.title + ' | ' + this.$moment(Item.dateFromPlain).format('DD.MM.YYYY') + (Item.multipleDays ? ' - ' + this.$moment(Item.dateToPlain).format('DD.MM.YYYY') : '' ) + ' | ' + Item.startPlain + ' - ' + Item.endPlain
      },
      setAlert() {
        return 'Naozaj chcete zrušiť udalosť?'
      },
      updateStatusCanceledData() {
        return Events
          .updateStatusCanceled({
            id: this.id,
            data: {}
          })
      },
      onUpdateStatusCanceled(response) {
        this.$emit('onUpdateStatusCanceled', response)
      }
    }
  }
</script>
