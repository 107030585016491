var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotBlankPage',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"fetchData":_vm.fetchData,"itemKey":_vm.Item,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs,"failLink":_vm.failLink},scopedSlots:_vm._u([{key:"action",fn:function({
        Item,
        reload
      }){return [_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":reload}}),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":() => {
          _vm.$router.push('/index-groups')
        }}}):_vm._e()]}},{key:"default",fn:function({
        calls,
        Item,
        reload
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Indexes',{key:calls,attrs:{"IndexGroup":Item},on:{"reload":reload}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }