<template>
  <ListPart
      :icon="icon"
      :title="title"
      :fetchData="fetchData"
      :itemKey="itemKey"
  >
    <template
        v-slot:default="{
          items
        }"
    >
      <v-row>
        <v-col
            v-for="(_Item, i) in items"
            :key="i"
            cols="12"
            sm="6"
            md="4"
        >
          <CardPart
              :title="_Item.title"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h3>
                    {{ _Item.value }}
                  </h3>

                  <div
                      v-html="_Item.content"
                  />
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>
      </v-row>
    </template>
  </ListPart>
</template>

<script>
  import Register from '@/services/register'

  import ListPart from '@/components/_Part/List'
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Contacts',
    components: {
      ListPart,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-contacts',
        title: 'Kontakty',
        itemKey: 'indexes'
      }
    },
    methods: {
      fetchData() {
        return Register
          .indexesIndexGroupValue({
            indexGroupValue: 'global_contact'
          })
      }
    }
  }
</script>
