<template>
  <UpdateSave
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="onUpdate"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.date"
            :error-messages="errors.date"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Dátum"
            type="date"
        />
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.start"
            :error-messages="errors.start"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Začiatok"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.end"
            :error-messages="errors.end"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Koniec"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.url"
            :error-messages="errors.url"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="URL"
            type="url"
        />
      </v-col>

      <template
          v-if="Item.volunteerId"
      >
        <v-col
            cols="12"
        >
          <v-checkbox
              v-model="ItemForm.agreementTermEmail"
              :error-messages="errors.agreementTermEmail"
              :dense="true"
              hide-details="auto"
              label="Chcem odoslať e-mail 'Zmena termínu online pohovoru'"
          />
        </v-col>

        <v-col
            v-if="ItemForm.url"
            cols="12"
        >
          <v-checkbox
              v-model="ItemForm.agreementUrlEmail"
              :error-messages="errors.agreementUrlEmail"
              :dense="true"
              hide-details="auto"
              label="Chcem odoslať e-mail 'Pozvánka na online pohovor'"
          />
        </v-col>
      </template>
    </template>
  </UpdateSave>
</template>

<script>
  import Interviews from '@/services/interviews'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'Update',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave
    },
    data() {
      return {
        maxWidth: 500,
        itemKey: 'Interview'
      }
    },
    methods: {
      fetchData() {
        return Interviews
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Upraviť pohovor'
      },
      setSubtitle(Item) {
        return Item.adminInitials + ' | ' + this.$moment(Item.datePlain).format('DD.MM.YYYY') + ' | ' + Item.startPlain + ' - ' + Item.endPlain
      },
      setItemForm(Item) {
        return {
          date: Item.datePlain,
          start: Item.startPlain,
          end: Item.endPlain,
          url: Item.url,
          agreementTermEmail: false,
          agreementUrlEmail: false
        }
      },
      updateData(ItemForm) {
        return Interviews
          .update({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
